@import url('../../../styles/component.css');

* {
    /* reset */
    margin: 0px;
}

:host {
    max-width: 700px;
}

section {
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 4.5rem;
    line-height: 4.5rem;
    font-weight: 600;
    letter-spacing: -0.06rem;
}
h1 > handwritten-text {
  
    letter-spacing: 0;
    line-height: 5.5rem;
}

h3 {
    font-size: 1.5rem;
    font-weight: 100;
}