:host {
    font-family: var(--handwritten--font-family);
    font-optical-sizing: var(--handwritten--font-optical-sizing);
    font-weight: 400;
    text-align: center;
    width: 50vw;
    margin: auto;
    
}




